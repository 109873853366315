
@font-face {
	font-family: "Astra_Sans";
	src: url("../fonts/pt-astra-sans_regular.ttf") format("truetype");
	font-style: normal;
	font-weight: 400;
}
@font-face {
	font-family: "Astra_Sans";
	src: url("../fonts/pt-astra-sans_bold.ttf") format("truetype");
	font-style: auto;
	font-weight: 600;
}
